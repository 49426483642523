import React from "react";
import { Box, Dialog, Typography, IconButton } from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/system";
import { toggleBuyETHDialog } from "~/api/choreography";
import { buyCryptoStateSelector } from "./buyCryptoReducer";

const DialogTitle = styled(MuiDialogTitle)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
});

const PRODUCTION_KEY = "pk_prod_Am_P9rH36xnx0DIyzI18qeOy_IIUHCL4Sgh2rCyuY2k0";

export function BuyCryptoDialog() {
    const { isBuyCryptoDialogOpen, amountToBuy } = useSelector(
        buyCryptoStateSelector,
    );

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(toggleBuyETHDialog());
    };

    return (
        <Dialog open={isBuyCryptoDialogOpen} onClose={handleClose}>
            <DialogTitle>
                <Typography variant="h6">Insufficient Funds</Typography>
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Box width="600px" height="400px" maxWidth="100%">
                <iframe
                    id="onramper-widget"
                    title="Onramper widget"
                    frameBorder="no"
                    allow="accelerometer; autoplay; camera; gyroscope; payment;"
                    src={`https://widget.onramper.com?onlyCryptos=ETH&onlyFiat=USD&defaultAmount=${amountToBuy}&color=266678&apiKey=${PRODUCTION_KEY}`}
                />
            </Box>
        </Dialog>
    );
}
